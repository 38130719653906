import React, { useState } from 'react';
import '../styles/Support.css';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import logo from '../assets/icons/logo.svg';
import Button from '../components/designsystem/button/Button';

const Support = () => {
//   const [searchQuery, setSearchQuery] = useState('');
  const [activeFaq, setActiveFaq] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const faqs = {
    'General Questions': [
      {
        question: "What is Dishdash?",
        answer: "Dishdash is an AI-powered meal planning app that helps you create personalized meal plans, track your nutrition, and discover delicious recipes tailored to your preferences."
      },
      {
        question: "How does Dishdash generate meal plans?",
        answer: "Dishdash uses AI to analyze your dietary preferences, health goals, and restrictions to suggest meals that fit your lifestyle."
      },
      {
        question: "Is Dishdash free to use?",
        answer: "Dishdash offers both a free plan and a premium subscription with additional features like advanced meal customization, calorie tracking, and exclusive recipes."
      },
      {
        question: "How do I start using Dishdash?",
        answer: "Simply download the app, sign up, and complete the onboarding process by selecting your dietary preferences. The AI will then generate a meal plan for you."
      }
    ],
    'Account & Subscription': [
      {
        question: "Do I need an account to use Dishdash?",
        answer: "Yes, creating an account helps us personalize your experience and save your meal plans, preferences, and progress."
      },
      {
        question: "How do I reset my password?",
        answer: 'Go to the login screen, click "Forgot Password?", and follow the instructions to reset your password via email.'
      },
      {
        question: "How can I cancel my subscription?",
        answer: "You can cancel your subscription anytime in the App Store (iOS) or Google Play Store (Android) under your subscription settings."
      },
      {
        question: "Will I lose my meal plans if I cancel my subscription?",
        answer: "You'll still have access to your saved meal plans, but premium features will be disabled once your subscription ends."
      }
    ],
    'Meal Planning & Customization': [
      {
        question: "Can I customize my meal plan?",
        answer: "Yes! You can swap meals, adjust portion sizes, and even exclude certain ingredients based on your preferences."
      },
      {
        question: "Does Dishdash support dietary restrictions?",
        answer: "Absolutely! You can set your dietary preferences (e.g., vegan, keto, gluten-free) during onboarding, and the AI will curate meal suggestions accordingly."
      },
      {
        question: "Can I track my calories and nutrition?",
        answer: "Yes, Dishdash provides detailed calorie and nutrient breakdowns for every meal in your plan."
      },
      {
        question: "Can I add my own recipes?",
        answer: "At the moment, Dishdash focuses on AI-generated meal plans, but we are working on a feature to allow users to add custom recipes."
      }
    ],
    'Payments & Refunds': [
      {
        question: "What payment methods do you accept?",
        answer: "We accept credit/debit cards, Apple Pay, Google Pay, and other regional payment methods depending on your location."
      },
      {
        question: "Do you offer refunds?",
        answer: "Refund policies depend on your app store provider (Apple/Google). If you experience an issue, please contact support for assistance."
      },
      {
        question: "Can I switch between subscription plans?",
        answer: "Yes, you can upgrade or downgrade your plan anytime from the app's subscription settings."
      }
    ],
    'Technical Support': [
      {
        question: "The app is not working properly. What should I do?",
        answer: "Try these steps:\n\n1. Restart the app\n2. Check for updates in the App Store or Play Store\n3. Clear cache or reinstall the app\n\nIf the issue persists, contact support@wearedishdash.co.uk for help."
      },
      {
        question: "Can I use Dishdash on multiple devices?",
        answer: "Yes, as long as you log in with the same account, your meal plans and preferences will sync across all your devices."
      },
      {
        question: "Does Dishdash require an internet connection?",
        answer: "Yes, an internet connection is required to fetch meal plans, track nutrition, and access AI-powered recommendations."
      }
    ]
  };

//   const helpArticles = [
//     {
//       title: "Getting Started with DishDash",
//       description: "Learn the basics of using DishDash, from setting up your profile to creating your first meal plan.",
//       icon: "fas fa-rocket",
//       link: "/help/getting-started"
//     },
//     {
//       title: "Meal Planning Guide",
//       description: "Master the art of meal planning with our comprehensive guide to creating balanced, delicious meal plans.",
//       icon: "fas fa-utensils",
//       link: "/help/meal-planning"
//     },
//     {
//       title: "Managing Your Preferences",
//       description: "Learn how to customize your dietary preferences, allergies, and cuisine choices for personalized recommendations.",
//       icon: "fas fa-sliders-h",
//       link: "/help/preferences"
//     }
//   ];

  return (
    <div className="support-container">
      <nav className="support-nav">
        <div className="nav-left">
          <Link to="/" className="brand">
            <img 
              src={logo} 
              alt="DishDash Logo" 
              className="brand-logo"
            />
          </Link>
        </div>
        <div className="nav-right">
            {/* <Link to="/signin" className="nav-link sign-in">Download iOS </Link> */}
            <Button
                buttonText="Download iOS "
                textColor="#ffffff"
                borderColor="#4FAE5A"
                backgroundColor="#4FAE5A"
            />
        </div>
      </nav>

      <div className="support-content">
        {/* <div className="search-section">
          <h1>How can we help?</h1>
          <div className="search-box">
            <i className="fas fa-search search-icon"></i>
            <input
              type="text"
              placeholder="Search help articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div> */}

        {/* Contact Information Section */}
        {/* <section className="contact-info-section">
          <h2>Contact Us</h2>
          <div className="contact-cards">
            <div className="contact-card">
              <i className="fas fa-envelope"></i>
              <h3>Email Support</h3>
              <p>support@dishdash.com</p>
              <p className="response-time">Response time: ~2 hours</p>
            </div>
            <div className="contact-card">
              <i className="fas fa-phone"></i>
              <h3>Phone Support</h3>
              <p>1-800-DISHDASH</p>
              <p className="response-time">Available 24/7</p>
            </div>
            <div className="contact-card">
              <i className="fas fa-comments"></i>
              <h3>Live Chat</h3>
              <p>Chat with our support team</p>
              <p className="response-time">Typical response: 5 mins</p>
            </div>
          </div>
        </section> */}

        {/* FAQs Section */}
        <section className="faqs-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-categories">
            {Object.entries(faqs).map(([category, questions], categoryIndex) => (
              <div key={categoryIndex} className="faq-category">
                <h3 className="category-title">{category}</h3>
                <div className="faq-list">
                  {questions.map((faq, index) => (
                    <div 
                      key={`${categoryIndex}-${index}`}
                      className={`faq-item ${activeFaq === `${categoryIndex}-${index}` ? 'active' : ''}`}
                      onClick={() => setActiveFaq(activeFaq === `${categoryIndex}-${index}` ? null : `${categoryIndex}-${index}`)}
                    >
                      <div className="faq-question">
                        <h4>{faq.question}</h4>
                        <i className={`fas fa-chevron-${activeFaq === `${categoryIndex}-${index}` ? 'up' : 'down'}`}></i>
                      </div>
                      <AnimatePresence>
                        {activeFaq === `${categoryIndex}-${index}` && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="faq-answer"
                          >
                            <p>{faq.answer}</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Help Articles Section */}
        {/* <section className="help-articles-section">
          <h2>Help Articles</h2>
          <div className="help-articles-grid">
            {helpArticles.map((article, index) => (
              <Link to={article.link} key={index} className="help-article-card">
                <div className="article-icon">
                  <i className={article.icon}></i>
                </div>
                <h3>{article.title}</h3>
                <p>{article.description}</p>
                <span className="read-more">Read More →</span>
              </Link>
            ))}
          </div>
        </section> */}

        {/* Support Form Section */}
        <section className="support-form-section">
          <h2>Submit a Support Request</h2>
          <p>Can't find what you're looking for? Send us a message.</p>
          <form onSubmit={handleSubmit} className="support-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="Your name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                placeholder="Your email address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
                placeholder="What's this about?"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                placeholder="How can we help you?"
                rows="5"
              ></textarea>
            </div>
            <Button
                buttonText="Send Message"
                textColor="#ffffff"
                borderColor="#4FAE5A"
                backgroundColor="#4FAE5A"
            />
            {/* <button type="submit" className="submit-button">
              Send Message
            </button> */}
          </form>
        </section>
      </div>
    </div>
  );
};

export default Support; 