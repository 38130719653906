import "./styles/App.css";
// import { useState } from "react";
import Home from "./pages/Home";
import Support from "./pages/Support";
import { Route, Routes } from "react-router-dom";
import "../node_modules/highlight.js/styles/atelier-cave-dark.css";
import { Toaster } from 'react-hot-toast';

function App() {

  return (
      <div>
        <Routes>
          <Route element={<Home />} path="/"></Route>
          <Route element={<Support />} path="/support"></Route>
        </Routes>
        <Toaster />
      </div>
  );
}

export default App;