import React, { useState, useEffect } from "react";
import "../../styles/Frontpage.css";
import Button from "../designsystem/button/Button";
import { Link } from "react-router-dom";
// import AvatarGroup from "../designsystem/avatar/AvatarGroup";
// import avatar1 from '../../assets/avatar/avatar1.png'
// import avatar2 from '../../assets/avatar/avatar2.png'
// import avatar3 from '../../assets/avatar/avatar3.png'
// import Modal from "../designsystem/modal";
// import favicon from "../../assets/icons/favicon.png"
// import TextInput from "../designsystem/TextInput"

import { motion } from "framer-motion";

import "highlight.js/styles/dracula.css";
import dishdashmobile from "../../assets/mockups/dishdashmobile.png";

// const avatars = [
//   { src: avatar1, alt: 'Avatar 1' },
//   { src: avatar2, alt: 'Avatar 2' },
//   { src: avatar3, alt: 'Avatar 3' },
// ];

const Section = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [highlightColors, setHighlightColors] = useState([
    "#4FAE5A", // color for "AI Powered"
    "#F3B304", // color for "Delivered"
  ]);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      setIsAnimated(true);
    }, 1000); // delay for starting animation

    return () => {
      clearTimeout(typingTimer);
    };
  }, []);

  const text = ["AI-Powered meal planning,", "made just for you!"];

  useEffect(() => {
    if (isAnimated) {
      const highlightTimer = setInterval(() => {
        setHighlightColors((prevColors) => {
          const newColors = [...prevColors];
          newColors[0] = getRandomColor(); // change color of "essence"
          newColors[1] = getRandomColor(); // change color of "delivered"
          return newColors;
        });
      }, 1000); // interval for changing color

      return () => {
        clearInterval(highlightTimer);
      };
    }
  }, [isAnimated]);

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`; // generate a random hex color code
  };

  const buttonVariants = {
    initial: { y: -1000 },
    animate: {
      y: 0,
      transition: { duration: 1, type: "spring", stiffness: 50, damping: 10 },
    },
  };

  // testing onscroll animation
  // const [scrollProgress, setScrollProgress] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollHeight =
  //       document.documentElement.scrollHeight - window.innerHeight;
  //     const scrolled = 1 - window.scrollY / scrollHeight;
  //     setScrollProgress(scrolled);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // const opacity = 1 - scrollProgress;
  // const translateY = `${scrollProgress * 100}%`;

  // modal component
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleButtonClick = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <>
      <div className="section-container">
        <h1 className="typing-text">
          {text.map((line, index) => (
            <span
              key={index}
              className={`animate-letter ${isAnimated ? "animate-typing" : ""}`}
            >
              {line.split(" ").map((word, i) => (
                <span
                  key={i}
                  className={`word ${
                    (word === "AI-Powered" && index === 0) ||
                    (word === "you" && index === 1)
                      ? "highlight"
                      : ""
                  }`}
                  style={{
                    color:
                      (word === "AI-Powered" && index === 0) ||
                      (word === "you" && index === 1)
                        ? highlightColors[index]
                        : "",
                  }}
                >
                  {word}
                  {i < line.split(" ").length - 1 && " "}
                </span>
              ))}
              {index < text.length - 1 && <br />}
            </span>
          ))}
        </h1>
        <div style={{ display: "flex" }}>
          <Button
            buttonText="Download for iOS "
            // icon={<AvatarGroup avatars={avatars} />}
            textColor="#ffffff"
            borderColor="#4FAE5A"
            backgroundColor="#4FAE5A"
            // onClick={handleButtonClick}
          />
          
          &nbsp;
          <Link to="/support" style={{ textDecoration: 'none' }}>
            <Button
              buttonText="Support"
              // icon={<AvatarGroup avatars={avatars} />}
              textColor="#151515"
              borderColor="#E6E5E2"
              backgroundColor="#ffffff"
            />
          </Link>
        </div>

        {/* start left column motion div */}
        <motion.div
          className="button-container left"
          variants={buttonVariants}
          initial="initial"
          animate={isAnimated ? "animate" : "initial"}
        >
          <motion.div
            // initial={{ y: -1000 }}
            // animate={{ y: 0 }}
            initial={{ y: -1000, rotate: 0 }}
            animate={{ y: 0, rotate: 1.93 }}
            transition={{ duration: 1, delay: 1 }}
            // style={{ marginBottom: "-40px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "-40px",
              position: "absolute",
              bottom: 0,
              left: 190,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Suya (Grilled Meat Skewers)"
              textColor="#ffffff"
              borderColor="#28629D"
              backgroundColor="#28629D"
            />
          </motion.div>
          <motion.div
            // initial={{ rotate: 0 }}
            initial={{ y: -1000 }}
            animate={{ y: 0, rotate: 1.93 }}
            // animate={{ rotate: 1.93 }}
            // transition={{ duration: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            // style={{ marginBottom: "-2px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "-2px",
              position: "absolute",
              bottom: 0,
              left: 150,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Jollof Rice"
              textColor="#ffffff"
              borderColor="#FF0000"
              backgroundColor="#FE6363"
            />
          </motion.div>
          <motion.div
            // initial={{ y: -1000 }}
            // animate={{ y: 0 }}
            initial={{ rotate: 0 }}
            animate={{ rotate: -1.77 }}
            transition={{ duration: 1, delay: 0.4 }}
            // style={{ marginBottom: "10px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "10px",
              position: "absolute",
              bottom: -50,
              left: 0,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Kelewele (Spicy Fried Plantains)"
              textColor="#ffffff"
              borderColor="#FFDC00"
              backgroundColor="#F6E88C"
            />
          </motion.div>
          <motion.div
            // initial={{ y: -1000 }}
            // animate={{ y: 0 }}
            initial={{ rotate: 0, y: -1000 }}
            animate={{ y: 0, rotate: 1.93 }}
            transition={{ duration: 1, delay: 0.9 }}
            // style={{ marginBottom: "-5px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "-5px",
              position: "absolute",
              bottom: 0,
              left: 10,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Amarula Malva Pudding"
              textColor="#ffffff"
              borderColor="#008000"
              backgroundColor="#61B461"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000 }}
            animate={{ y: 0 }}
            transition={{ duration: 1, delay: 0.9 }}
            // style={{ marginBottom: "-8px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "-8px",
              position: "absolute",
              bottom: 0,
              left: 290,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Injera with Doro Wat"
              textColor="#ffffff"
              borderColor="#C137D8"
              backgroundColor="#D651EC"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000 }}
            animate={{ y: 0, rotate: -7.63 }}
            transition={{ duration: 1, delay: 1.1 }}
            // style={{ marginBottom: "-5px", whiteSpace: "nowrap" }}
            style={{
              marginBottom: "-5px",
              position: "absolute",
              bottom: 0,
              left: 220,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Egusi Soup"
              textColor="#ffffff"
              borderColor="#FFA500"
              backgroundColor="#FFC04D"
            />
          </motion.div>
        </motion.div>
        {/* end left column motion div */}

        {/* start right column motion div */}
        <motion.div
          className="button-container right"
          variants={buttonVariants}
          initial="initial"
          animate={isAnimated ? "animate" : "initial"}
        >
          <motion.div
            // initial={{ rotate: 0, y: -1000 }}
            // animate={{ rotate: 1.93, y: 0 }}
            // transition={{ duration: 1 }}
            initial={{ y: -1000 }}
            animate={{ y: 0 }}
            transition={{ duration: 1, delay: 0.9 }}
            style={{
              marginBottom: "-8px",
              position: "absolute",
              bottom: 0,
              right: 370,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Fufu"
              textColor="#ffffff"
              borderColor="#000000"
              backgroundColor="#000000"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000, rotate: 0 }}
            animate={{ y: 0, rotate: -7.63 }}
            transition={{ duration: 1, delay: 1 }}
            style={{
              marginBottom: "-2px",
              position: "absolute",
              bottom: 0,
              right: 310,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Waakye"
              textColor="#ffffff"
              borderColor="#008000"
              backgroundColor="#61B461"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000, rotate: 0 }}
            animate={{ y: 0, rotate: -2.84 }}
            // transition={{ duration: 0.4 }}
            transition={{ duration: 0.4, delay: 0.9 }}
            style={{
              marginBottom: "-2px",
              position: "absolute",
              bottom: 0,
              right: 190,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Akara (Bean Fritters)"
              textColor="#ffffff"
              borderColor="#B15858"
              backgroundColor="#D76868"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000, rotate: 0 }}
            animate={{ y: 0, rotate: 3.51 }}
            transition={{ duration: 1, delay: 1.1 }}
            style={{
              marginBottom: "2px",
              position: "absolute",
              bottom: -35,
              right: 20,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Pounded Yam and Egusi Soup"
              textColor="#ffffff"
              borderColor="#000000"
              backgroundColor="#000000"
            />
          </motion.div>
          <motion.div
            initial={{ y: -1000, rotate: 0 }}
            animate={{ y: 0, rotate: -1.77 }}
            // transition={{ duration: 1 }}
            transition={{
              duration: 1,
              delay: 1,
              type: "bounce",
              stiffness: 10,
            }}
            style={{
              marginBottom: "0px",
              position: "absolute",
              bottom: 8,
              right: 50,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Samak Tibs (Fish Stew)"
              textColor="#ffffff"
              borderColor="#FFDC00"
              backgroundColor="#F6E88C"
            />
          </motion.div>

          <motion.div
            // initial={{ rotate: 0 }}
            // animate={{ rotate: 1.93 }}
            // transition={{ duration: 1 }}
            initial={{ y: -1000 }}
            animate={{ y: 0, rotate: 1.93 }}
            transition={{ duration: 1, delay: 1 }}
            style={{
              marginBottom: "-40px",
              position: "absolute",
              bottom: 0,
              right: 190,
              whiteSpace: "nowrap",
            }}
          >
            <Button
              buttonText="Mandazi (East African Doughnut)"
              textColor="#ffffff"
              borderColor="#105EA2"
              backgroundColor="#0074D9"
            />
          </motion.div>
        </motion.div>
        {/* end right column motion div */}
        
        {/* <div className="herophone-container">
          <motion.div
            className="herophone-footer"
            style={{ translateY, opacity }}
          >
            <img width="350" src={dishdashmobile} alt="Phone Mockup" />
          </motion.div>
        </div> */}
        <div className="herophone-container">
          <motion.div
            className="herophone-footer"
            // style={{ opacity, position: "fixed", top: "50%", left: "50%", transformx: "translate(-50%, -50%)" }}
          >
            {/* Phone mockup image */}
            <img width="350" src={dishdashmobile} alt="Phone Mockup" />
          </motion.div>
        </div>
        {/* {isModalOpen && (
          <Modal onClose={closeModal}>
            <div>
              <img width="30" src={favicon} alt="" />
            </div>
            <br/>
            <p style={{fontSize: 13}}>Become a Dishdash vendor or rider</p>
            <br/>
            <br/>
            <TextInput
              type="text"
              placeholder="Email Address"
              style={{ width: "100%", height: 40 }}
            />
            <br/>
            <br/>

            <div style={{display:'flex'}}>
              <div>
                <Button
                buttonText="Close"
                // icon={<AvatarGroup avatars={avatars} />}
                textColor="#ffffff"
                borderColor="#151515"
                backgroundColor="#151515"
                onClick={closeModal}
              />
            </div>
            <div>
              <Button
                buttonText="Submit"
                // icon={<AvatarGroup avatars={avatars} />}
                textColor="#ffffff"
                borderColor="#151515"
                backgroundColor="#151515"
                onClick={closeModal}
              />
            </div>
            </div>
          </Modal>
        )} */}
      </div>
    </>
  );
};

export default Section;